// SelectDevice.js
import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import "animate.css";
import axios from "axios";
import api from "./api.js";
// import mobileData from "../../../backend/controllers/mobileData_controller"
const SelectDevice = ({ onClick }) => {
  const [showiPhoneModal, setShowiPhoneModal] = useState(false);
  // const [showiPadModal, setShowiPadModal] = useState(false);
  // const [showAppleWatchModal, setShowAppleWatchModal] = useState(false);
  const [data, setData] = useState([]);
  async function handleClick(val) {
    try {
      await api.get(`/get-all-devices/${val}`).then((response) => {
        setData(response?.data?.data);
        openiPhoneModal();
      });
    } catch (err) {}
  }
  const openiPhoneModal = () => setShowiPhoneModal(true);
  const closeiPhoneModal = () => setShowiPhoneModal(false);

  return (
    <div className="py-10" onClick={onClick}>
      <div className="container mx-auto" id="products">
        <div
          className="text-blue-800 font-bold lg:text-[40px] md:text-[25px] text-[23px]
          text-center mb-10 pt-16"
        >
          SELECT A PRODUCT
        </div>
        <div className="grid grid-cols-3 text-center font-bold text-base lg:text-2xl ">
          <div onClick={() => handleClick("mobile")} className="cursor-pointer">
            iPhone
            <img
              src="/product/phone_1.webp"
              alt="iPhone"
              className="h-56 md:h-80  w-96 md:w-full object-contain object-center"
            />
          </div>
          <div onClick={() => handleClick("laptop")} className="cursor-pointer">
            Laptop
            <img
              src="/product/ipad_1.webp"
              alt="Laptop"
              className="h-56 md:h-80  w-96 md:w-full object-contain "
            />
          </div>
          <div onClick={() => handleClick("watch")} className="cursor-pointer">
            Watch
            <img
              src="/product/iwatch_1.webp"
              alt="Watch"
              className="h-56 md:h-80  w-96 md:w-full object-contain "
            />
          </div>

          <Modal
            product="PRODUCTS"
            showModal={showiPhoneModal}
            closeModal={closeiPhoneModal}
            // placeholder="Search an iPhone ..."
            // images={iPhoneImage}
            titles={data}
            sizes={data}
          />

          {/* <Modal
            product="iPad"
            showModal={showiPadModal}
            closeModal={closeiPadModal}
            placeholder="Search an iPad ..."
            images={iPadImage}
            titles={iPadTitles}
            sizes={iPadSizes}
          />

          <Modal
            product="Apple Watch"
            showModal={showAppleWatchModal}
            closeModal={closeAppleWatchModal}
            placeholder="Search an Apple Watch ..."
            images={appleWatchImage}
            titles={appleWatchTitles}
            sizes={appleWatchSizes}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default SelectDevice;
