import React, { useState, useEffect } from "react";
import api from "./api";

export function ConditionForm({ mainState, setMainState }) {
  const [conditions1, setConditions] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState([]);
  // const [currentValue, setCurrentValue] = useState("");
  const [conditionpriceData, setPriceData] = useState("");
  const [val1, setval1] = useState(mainState.data2.updated_price);

  async function getCurrentValue() {
    setMainState({
      ...mainState,
      loader: true,
    });
    try {
      const payload = {
        current_value: mainState.data2.updated_price,
        step: "conditions",
        step_id: selectedCondition,
        device_id: mainState.data1.sendobjj.device_id,
        device_type: mainState.data1.sendobjj.device_type,
      };

      const response = await api.post(`/get-current-value`, payload);
      // setCurrentValue(response?.data?.current_value);
      if (response.data.status) {
        setPriceData(response.data.data);
        setval1(response.data.data.updated_price);
        setMainState({
          ...mainState,
          // conditionId: conditionId,
          loader: false,
          condition_amount: response.data.data.updated_price,
          data3_getval: response.data.data,
          // selected_condition: selectedCondition,
          // condition1: condition1
        });
      }
    } catch (error) {
      console.error("Error fetching current value:", error);
    }
  }

  useEffect(() => {
    const originalOverflowStyle = document.body.style.overflow;
    document.body.style.overflow = "hidden"; // Disable scrolling when modal is open

    return () => {
      document.body.style.overflow = "hidden"; // Re-enable scrolling when modal is closed
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setMainState({ ...mainState, loader: true });
        const response = await api.get(
          `/get-device/mobile/${mainState.data1.sendobjj.device_id}`
        );
        setConditions(response.data.data.conditions);
      } catch (error) {
        console.error("Error fetching conditions:", error);
      } finally {
        setMainState({ ...mainState, loader: false });
      }
    }
    fetchData();
    // console.log(mainState, "second test");
  }, []);

  useEffect(() => {
    if (selectedCondition) {
      getCurrentValue(selectedCondition);
    }
  }, [selectedCondition]);

  const handleConditionChange = (conditionId, condition1) => {
    // console.log(conditionpriceData, "eededeeded");
    setMainState({
      ...mainState,
      data3: {
        conditionId: conditionId,
        condition_details: condition1,
      },
      // amount: conditionpriceData.updated_price,
    });
    setSelectedCondition(conditionId);
  };

  return (
    <div className="px-2 md:px-0">
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">
          Device Condition
        </legend>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          What is the condition of the phone?
        </p>
        <div className="mt-6 space-y-6">
          {conditions1.map((condition1) => (
            <div
              key={condition1.condition_id}
              className="flex items-center gap-x-3"
            >
              <input
                id={condition1.condition_id}
                name="condition"
                type="radio"
                required
                autoFocus
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                onChange={() =>
                  handleConditionChange(
                    condition1.condition_id,
                    condition1,
                    condition1.condition_id
                  )
                }
              />
              <label
                htmlFor={condition1.condition_id}
                className="block text-left text-sm font-medium leading-6 text-gray-900"
              >
                {condition1.condition_title}
                <p className="text-xs text-gray-400">
                  {condition1.condition_description}
                </p>
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      <div className="mt-3 text-sm">
        <span>The current value of the phone:</span>

        <span> $ {val1}</span>
      </div>
    </div>
  );
}
