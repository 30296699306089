import React, { useState } from "react";
import Steps from "./Steps.jsx";

function MultiForm() {
  return (
    <div className="bg-gradient-to-t to-[#352eb0] from-[#13a4f1] p-12 md:p-2 mt-5">
      <div className="flex flex-col container mx-auto lg:p-7">
        <div className="flex flex-col lg:flex-row px-3 items-center gap-y-10 lg:gap=y-0 lg:gap-x-10">
          {" "}
          <div className="md:basis-2/4">
            <img
              src="/stepsbanner.webp"
              alt="image"
              className="h-[300px] md:h-[400px] lg:h-auto"
            />
          </div>
          <div className="md:basis-2/4 ">
            <Steps />
          </div>{" "}
        </div>
      </div>
    </div>
  );
}
export default MultiForm;
