import React, { useState, useEffect } from "react";
import { MapPinIcon } from "@heroicons/react/20/solid";
import api from "./api";

const Location = () => {
  const [selectedShopIndex, setSelectedShopIndex] = useState(0);
  const [shopDetails, setShopDetails] = useState([]);
  const [iframeLocURL, setIframeLocURL] = useState("");
  const [loader, setLoader] = useState(true);

  async function fetchShopDetails() {
    try {
      const response = await api.get("/get-shop-details");
      setShopDetails(response.data.data);
      getShopLocation(response.data.data[0]);
    } catch (error) {
      console.error("Error fetching shop details:", error);
    }
  }

  useEffect(() => {
    fetchShopDetails();
  }, []);

  const getShopLocation = (shopDetails) => {
    let url = `https://maps.google.com/maps?q=${shopDetails.shop_lat},${shopDetails.shop_long}&z=14&output=embed`;
    setIframeLocURL(url);
  };

  const handleLocationClick = (index) => {
    setSelectedShopIndex(index);
    const selectedShop = shopDetails[index];
    const url = `https://maps.google.com/maps?q=${selectedShop.shop_lat},${selectedShop.shop_long}&z=14&output=embed`;
    setIframeLocURL(url);
  };

  const handleImageLoad = () => {
    setLoader(false);
  };

  return (
    <div className="map">
      <div id="locations">
        <div className="bg-white text-indigo-800  text-3xl font-bold text-center pt-10 md:pt-20">
          Our Store Locations
        </div>
        <div className="container mx-auto px-3">
          <div className="flex flex-col md:flex-row md:justify-center pt-5 md:py-10 mix">
            <div className="md:basis-1/4 overflow-y-auto custom-scrollbar md:h-[30rem] bg-white">
              <div className=" p-5 z-10 ">
                <div className={`custom-scrollbar cursor-pointer`}>
                  {shopDetails.map((shop, index) => (
                    <div
                      className={`mb-3 ${
                        selectedShopIndex === index
                          ? "opacity-100"
                          : "opacity-50"
                      }`}
                      onClick={() => handleLocationClick(index)}
                      key={shop.shop_id}
                    >
                      <div
                        className={`flex items-center gap-x-5 text-indigo-800 font-bold lg:text-xl capitalize`}
                      >
                        <MapPinIcon className="w-5 h-5 text-indigo-800 hidden lg:block " />
                        {shop.shop_address}
                      </div>
                      <div className="font-normal text-sm lg:text-base text-indigo-800 lg:pl-10">
                        Contact : {shop.contact}
                      </div>
                      <button
                        className={`bg-[#352eb0] text-white p-2 lg:text-base font-bold my-2 rounded-md lg:ml-10`}
                        onClick={() =>
                          window.open(
                            `https://maps.google.com/maps?q=${shopDetails[selectedShopIndex].shop_lat},${shopDetails[selectedShopIndex].shop_long}`
                          )
                        }
                      >
                        Get Directions
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="md:basis-3/4">
              <div className="border-4 border-gray-200">
                {loader && (
                  <div className="absolute  bg-white/30 h-full w-full flex items-center justify-center top-0 left-0 ">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 "></div>
                  </div>
                )}
                <iframe
                  title="Locations"
                  src={`${iframeLocURL}`}
                  width="100%"
                  // height="400"
                  className="lg:h-[600px] md:h-[400px] h-[400px]"
                  onLoad={handleImageLoad}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
