import React, { useState, useEffect } from "react";
import api from "./api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
export function PriceForm({ mainState, setMainState }) {
  const [updatedPrice, setUpdatedPrice] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [shopDetails, setShopDetails] = useState([]);
  const [selectedShop, setSelectedShop] = useState([]);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const timeSlots = [
    { startTime: "09:00", endTime: "11:00" },
    { startTime: "12:00", endTime: "14:00" },
    { startTime: "15:00", endTime: "17:00" },
  ];
  const handleSelectChange = (event) => {
    // Get the selected option value
    const selectedValue = event.target.value;
    // Update the selected option state
    setSelectedOption(selectedValue);
  };

  const handleDateChange = (event) => {
    // Update the selected date state when the date input changes
    setSelectedDate(event.target.value);
    // Log the selected date to the console
    // console.log("Selected date:", event.target.value);
  };

  const handleShopChange = (event) => {
    setSelectedShop(event.target.value);
  };

  useEffect(() => {
    // Check if all required fields are filled to enable submit button
    if (selectedDate && selectedOption && selectedShop) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  }, [selectedDate, selectedOption, selectedShop]);

  const handleSubmit = (event) => {
    const selectedDateTime = {
      date: selectedDate,
      startTime: selectedOption,
      endTime: timeSlots.find((slot) => slot.startTime === selectedOption)
        .endTime,
      shop: selectedShop,
    };

    toast.success("Form submitted successfully!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    // console.log("Selected Date and Time:", selectedDateTime);
    setTimeout(() => {
      window.location.reload();
    }, 4000);
  };

  async function fetchShopDetails() {
    try {
      const response = await api.get("/get-shop-details");
      setShopDetails(response.data.data);
    } catch (error) {
      console.error("Error fetching shop details:", error);
    }
  }

  async function getCurrentValue() {
    try {
      let payload = {
        condition: mainState.data3.condition_details,
        carrier: mainState.data2,
        storage: mainState.data4.storage,
        device_id: mainState.data1.sendobjj.device_id,
        quoted_price: mainState.data4.amount_from_storage.updated_price,
        device_name: mainState.data1.sendobjj.device_name,
      };
      const response = await api.post(`/get-current-value`, payload);
      setUpdatedPrice(response);
    } catch (error) {
      console.error("Error fetching current value:", error);
    }
  }

  useEffect(() => {
    getCurrentValue();
  }, []);

  useEffect(() => {
    fetchShopDetails();
  }, []);

  useEffect(() => {
    const originalOverflowStyle = document.body.style.overflow;
    document.body.style.overflow = "hidden"; // Disable scrolling when modal is open

    return () => {
      document.body.style.overflow = "hidden"; // Re-enable scrolling when modal is closed
    };
  }, []);

  return (
    <div className="px-2 md:px-0">
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">
          Phone Price
        </legend>
        <p className="mt-1 text-sm leading-6 text-gray-600"></p>
        <div className="mt-6 space-y-6">
          <div className="flex justify-center items-center font-medium gap-x-5 text-gray-500 text-center">
            <div className="font-bold">Your device is valued at :</div>
          </div>

          <div className="text-center text-3xl font-extrabold text-blue-900">
            <span>${updatedPrice && updatedPrice.updated_price}</span>
          </div>

          {/* <AppointmentForm /> */}
          <fieldset>
            <form onSubmit={handleSubmit} className="mt- space-y-2">
              <div className="flex justify-center ">
                <div className="flex flex-col space-y-5">
                  <div>
                    <span className="text-base font-medium">
                      {" "}
                      Select Date :{" "}
                    </span>{" "}
                    <input
                      type="date"
                      className="text-base outline-none"
                      id="date-picker"
                      value={selectedDate}
                      onChange={handleDateChange}
                      min={new Date().toISOString().split("T")[0]}
                      required
                    />
                  </div>
                  <div className="relative inline-block text-left">
                    <select
                      className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-base mb-5"
                      value={selectedOption}
                      onChange={handleSelectChange}
                      required
                    >
                      <option value="" disabled hidden>
                        Select a time slot
                      </option>
                      {timeSlots.map((slot, index) => (
                        <option key={index} value={slot.startTime}>
                          {`${slot.startTime} - ${slot.endTime}`}
                        </option>
                      ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      ></svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center">
                <span className="text-base font-medium">Select Shop:</span>
                <select
                  className="block w-96 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-base mb-5 capitalize "
                  value={selectedShop}
                  onChange={handleShopChange}
                >
                  <option value="" disabled>
                    Select a shop
                  </option>
                  {shopDetails.map((shop, index) => (
                    <option
                      key={index}
                      value={(shop.shop_id, shop.shop_address)}
                    >
                      {shop.shop_address}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex justify-end">
                <Link to="/thankyou">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={submitButtonDisabled}
                    className="mt-2 px-4 py-2 bg-indigo-600 text-white text-sm  font-medium rounded hover:bg-indigo-700 focus:outline-none focus:ring focus:border-indigo-300"
                  >
                    Submit
                  </button>
                </Link>
              </div>
            </form>
          </fieldset>
        </div>
      </fieldset>
    </div>
  );
}
