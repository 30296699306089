import { ChevronDoubleRightIcon } from "@heroicons/react/20/solid";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/outline";
import React from "react";

const sell = () => {
  return (
    <div>
      <div className="p-10 lg:py-10 bg-blue-300  ">
        <div className="container mx-auto ">
          <div className="grid grid-cols-1 lg:grid-cols-5">
            <div className="flex flex-col items-center">
              <img src="/quote.webp" className="h-40 md:h-52" alt="Get Quote" />
              <div>
                <h1 className="font-bold text-2xl text-center">
                  Quick and Easy <br /> quotes
                </h1>
                <div className="text-center font-medium text-lg">
                  Get a quote of your phone <br /> in just few clicks.
                </div>
              </div>
            </div>
            <div className="hidden lg:flex items-center">
              <ChevronDoubleRightIcon className="w-32 text-yellow-200" />
            </div>
            <div className=" flex justify-center lg:hidden">
              <ChevronDoubleDownIcon className="w-32 text-yellow-200 mx-auto" />
            </div>
            <div className="flex flex-col items-center">
              <img
                src="/appointment.webp"
                className="h-40 md:h-52"
                alt="Schedule an appointment"
              />
              <div>
                <h1 className="font-bold text-2xl text-center">
                  Book Your Appointment
                </h1>
                <div className="text-center font-medium text-lg">
                  Fill out the form below to
                  <br />
                  schedule your appointment quick and easy.
                </div>
              </div>
            </div>
            <div className="hidden lg:flex items-center">
              <ChevronDoubleRightIcon className="w-32 text-yellow-200" />
            </div>
            <div className=" flex justify-center lg:hidden">
              <ChevronDoubleDownIcon className="w-32 text-yellow-200 mx-auto" />
            </div>
            <div className="flex flex-col items-center">
              <img src="/payment.webp" className="h-40 md:h-52" alt="Get Instant payment for your device" />
              <div>
                <h1 className="font-bold text-2xl text-center">Instant Cash</h1>
                <div className="text-center font-medium text-lg">
                  Get instant cash for your
                  <br /> phone at time of pickup.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default sell;
