import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import User from "./pages/User.js";
import TermsofService from "./pages/termsofservice.jsx";
import Thankyou from "./pages/thankyou.jsx";

function App({}) {
  // Router
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<User />} />
          <Route path="/termsofservice" exact element={<TermsofService />} />
          <Route path="/thankyou" exact element={<Thankyou />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
