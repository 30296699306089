import React from "react";
import "animate.css";
import { IoLogoApple } from "react-icons/io";
const Steps = () => {
  return (
    <div>
      <div className="md:my-10">
        <div className="animate__animated  animate__fadeInRight animate__delay-2s">
          <div className="flex items-center gap-x-2 text-[20px]  lg:text-[40px] font-bold text-white underline underline-offset-8">
            Sell Your Device In Few Clicks
            {/* <div className="animate__animated animate__rotateInDownLeft animate__delay-5s">
              <IoLogoApple className="text-white " />
            </div> */}
          </div>
          <ol className="text-[13px] md:text-[16px] lg:text-[25px] text-white mt-4">
            <li>
              <b className="text-yellow-400">1. Select your device:</b> Choose your device
              model from the list provided.
            </li>
            <li>
              <b className="text-yellow-400">2. Add device details:</b> Provide information about
              the condition of your device, including any scratches, dents, or
              malfunctions. Also, specify the carrier and storage capacity of
              your device.
            </li>
            <li>
              <b className="text-yellow-400">3. Get a quote:</b> Based on the details you provided, you'll
              receive a quote for your device. This quote will be calculated
              considering the condition, carrier, and storage capacity.
            </li>
            <li>
              <b className="text-yellow-400">4. Schedule an appointment:</b> Once you receive the quote, you
              can schedule an appointment at our store. During the appointment,
              our experts will assess your device and if it matches the provided
              details, you'll receive the quoted price for selling your device.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Steps;
