import React, { useState } from "react";
import "../App.css";
import { Link } from "react-router-dom";
export default function Thankyou() {
  const phoneNumber = "919082218978"; // Replace with your WhatsApp number
  const message =
    "Hello! Wanted to sell my iPhone, When can we schedule store visit";
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  return (
    <>
      <div className="flex flex-col justify-center items-center  h-screen">
        <div
          className={`w-32 h-32  border-gray-600 border-4 rounded-full flex justify-center items-center cursor-pointer  animate-tick `}
        >
          <span className="text-green-500 text-[6rem]">&#10003;</span>
        </div>
        <div className="text-black font-bold text-2xl mt-5">
          Thankyou for submitting...
        </div>
        <div className="mt-2 text-lg font-medium leading-4">
          {" "}
          Contact us : 1234567890
        </div>
        <div className="flex  items-center gap-4 mt-2">
          <div>
            <Link to="/">
              <button
                type="submit"
                className="mt-2 px-4 py-2 bg-indigo-600 text-white text-sm  font-medium rounded hover:bg-indigo-700 focus:outline-none focus:ring focus:border-indigo-300"
              >
                Home
              </button>
            </Link>
          </div>
          <div>
            <Link to={whatsappLink}>
              <button
                type="submit"
                className="mt-2 px-4 py-2 bg-indigo-600 text-white text-sm  font-medium rounded hover:bg-indigo-700 focus:outline-none focus:ring focus:border-indigo-300"
              >
                Whatsapp
              </button>
            </Link>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}
